import Layout from "../../../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../../../components/seo"
import React, { useState, useEffect } from "react"
import dayjs from "dayjs"

import InTheNewsList from "../../../components/Media Components/InTheNewsList"
import DateFilter from "../../../components/Media Components/DateFilter"
import Pagination from "../../../components/Media Components/Pagination"
import "../../../css/resources.css"
import "../../../css/pageBanner.css"

const isSameOrAfter = require("dayjs/plugin/isSameOrAfter")
const isSameOrBefore = require("dayjs/plugin/isSameOrBefore")
const isBetween = require("dayjs/plugin/isBetween")
dayjs.extend(isBetween)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

function IntheNews() {
  const drupalData = useStaticQuery(graphql`
  query InTheNewsQuery {  
    allNodeInTheNews {
    nodes {
      title
      field_meta_title
      field_meta_desc
      field_in_the_news_link_text
      field_in_the_news_heading
      field_in_the_news_date
      field_inthenews_info
      field_content_intro {
        processed
      }
      field_content_end {
        processed
      }
      field_press_release_schema_scrip {
        processed
      }
      relationships {
        field_in_the_news_banner_image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    }
    }`)
const data = drupalData.allNodeInTheNews.nodes

const [startDate, setStartDate] = useState('')
const [endDate, setEndDate] = useState('')
  // Pagination starts

  const [showPerPage, setShowPerPage] = useState(4)
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  })
  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end })
  }

  // Pagination ends

  // Date filter starts
  const [filterData, setFilterData] = useState([])

  useEffect(() => {
    let temp = []

    if (!startDate && !endDate) {
      data.forEach(item => {
        temp.push(item)
      })
      setFilterData(data)
    } else if (startDate && !endDate) {
      data.forEach(item => {
        if (dayjs(item.field_in_the_news_date).isSameOrAfter(startDate)) {
          temp.push(item)
        }
      })
    } else if (endDate && !startDate) {
      data.forEach(item => {
        if (dayjs(item.field_in_the_news_date).isSameOrBefore(endDate)) {
          temp.push(item)
        }
      })
    } else {
      data.forEach(item => {
        if (
          dayjs(item.field_in_the_news_date).isBetween(
            startDate,
            endDate,
            "[]"
          )
        ) {
          temp.push(item)
        }
      })
    }
    setFilterData(temp)
  }, [startDate, endDate])

  const getStartDate = value => {
    setStartDate(value)
  }
  const getEndDate = value => {
    setEndDate(value)
  }
  // Date filter ends

  return (
    <Layout>
      <SEO
        title="In The News | Tata Studi"
        lang="en"
        description="Find resources to help your child in developing a good study habit and become an independent and confident learner | Tata Studi"
      />
      <h1 className="displayNone">Videos | Tata Studi</h1>
      <div className="pageBanner blogsPage" style={{ position: "initial" }} />
      <div className="blogs_banner">
        <span>In The News</span>
      </div>

      <DateFilter getStartDate={getStartDate} getEndDate={getEndDate} />
      <div className="div_blogs">
        {filterData &&
          filterData.slice(pagination.start, pagination.end).map(item => (
          <InTheNewsList item={item} key={item.id} />
        ))}
      </div>
      <div className="mt-5">
        <Pagination
          showPerPage={showPerPage}
          onPaginationChange={onPaginationChange}
          total={data.length}
        />
      </div>
      <div
        className="row no-gutters"
        style={{ height: "150px", marginTop: "100px" }}
      ></div>
    </Layout>
  )
}

export default IntheNews
