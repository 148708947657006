import React, { useState } from "react"
import dayjs from "dayjs"
import { Link } from "gatsby"
import "../../css/MediaPage/presslist.css"

const InTheNewsList = ({ id, item }) => {
  const [isActive, setIsActive] = useState(false)
  return (
    <div className="row my-4 mx-3 mx-lg-5">
      <div className="col-12">
        <div className="accordion">
          <div className="card" style={{ borderRadius: "1rem" }}>
            <div class="card-body accordian-title">
              <div className="row">
                <div className="col-10">
                  <div className="mb-0 text-left accordian-heading">
                    {item.field_in_the_news_heading}
                  </div>
                </div>

                <div
                  className="col-2"
                  data-toggle="collapse"
                  data-target={`#${item.field_in_the_news_link_text}`}
                  style={{ cursor: "pointer" }}
                  onClick={() => setIsActive(!isActive)}
                >
                  {isActive ? (
                    <i className="fa fa-minus accordian-icon" />
                  ) : (
                    <i className="fa fa-plus accordian-icon" />
                  )}
                </div>
              </div>
            </div>

            <div
              id={item.field_in_the_news_link_text}
              className="collapse"
              data-parent={`#${item.field_in_the_news_link_text}`}
            >
              <div className="card-body">
                <div className="acco-content">
                  <span
                    className="blog-date font-lato"
                    style={{ color: "#00ABFF" }}
                  >
                    {dayjs(item?.field_in_the_news_date).format("DD MMMM YYYY")}
                  </span>
                  <br />
                  <p className="blog_text mt-1 font-lato">
                    {item.field_inthenews_info}
                  </p>
                  <Link to={item.field_in_the_news_link_text}>
                    <span
                      className="float-right font-lato mb-3"
                      style={{ color: "#00ABFF" }}
                    >
                      Show More...
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InTheNewsList
